import style from "./style.module.css";
import Header from '@components/Header';
import Container from '@components/Container';
import Footer from '@components/Footer';

const BlogCS2Page = () => {
    return (
    <div>
        <Header type="mini"/>
        <section className={style.contacts_section}>
            <Container>
                <div className={style.content_article}>
                    <h2>CS 2 launch options to increase FPS</h2>

                    <h3>CS 2 launch options for weak PC</h3>
                    <p>Many parameters are taken from CS:GO. It's possible that Valve may disable support for some of them over time.</p>
                    <h3>Attention, many parameters may not work.</h3>
                    <p>–d3d9ex — allows you to quickly collapse and expand the game. Reduces CPU load by approximately 40%.</p>
                    <p>–high — launches CS 2 with high priority</p>
                    <p>–freq X — sets the screen refresh rate. Instead of “X”, specify the parameter corresponding to the Hertz of your monitor.</p>
                    <p>-refresh X — changes the screen refresh rate. Replace "X" with the desired parameter.</p>
                    <p>+fps_max 0 — Removes the frames per second limit.</p>
                    <p>+rate 124000 — sets the maximum amount of data that the host can receive (bits/sec).</p>
                    <p>+cl_cmdrate 128 — sets the maximum number of packets that are sent to the server per second.</p>
                    <p>+cl_updaterate 128 — sets the maximum number of update packets that the server sends per second.</p>
                    <p>+ex_interpratio 1 — network parameter.</p>
                    <p>–threads X — Instead of "X" indicate the number of cores that the processor needs to use.</p>
                    <p>cl_interp 0 — smoothes the picture.</p>
                    <p>+cl_interp_ratio 1 — speeds up connection to the server.</p>
                    <p>+mat_queue_mode 2 — Enables multi-core game processing.</p>
                    <p>-nojoy — disables joystick support.</p>
                    <br/>
                    <p>-high -threads 6 +fps_max 0 +cl_interp 0 +cl_interp_ratio 1 +rate 124000 +cl_updaterate 128 +cl_cmdrate 128 +mat_queue_mode 2 -freq 165 -refresh 165 -d3d9ex -nojoy</p>
                    <h3>To enter the parameters, open the library in the Steam client. Right-click on Counter-Strike and select Properties. At the very bottom of the “General” tab is “Launch Options”</h3>
                    <img src="/img/cs2params.png" alt="params"/>
                </div>
            </Container>
        </section>
        <Footer/>
    </div>
    )
}

export default BlogCS2Page;