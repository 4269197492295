import style from './style.module.css';

import Header from '@components/Header';
import Title from '@components/Title';
import Container from '@components/Container';
import Footer from '@components/Footer';
import {Link} from "react-router-dom";

const BlogPage = () => {
    const Items = [
        {
            id: 1,
            title: "The best parameters for launching CS GO",
            text: `You should not ignore the launch options as they optimize and improve the performance of the game. We have shared the most useful ones.`,
            src: '/img/csblog.jpg',
            alt: 'The best parameters for launching CS GO',
            link: '/best-CS-GO-launch-options'
        },
        {
            id: 2,
            title: "CS 2 options to increase FPS",
            text: `CS 2 is more demanding on computer performance than the previous part. Because of this, many players encounter lags and low FPS, which does not allow them to spend time comfortably. In this article we will provide useful launch parameters for increasing FPS in CS 2.`,
            src: '/img/cs2.jpeg',
            alt: 'CS 2 options to increase FPS',
            link: '/CS-2-options-to-increase-fps'
        },
        {
            id: 3,
            title: "The best white skins in cs",
            text: `White is the ultimate. It pairs perfectly with any other color, highlighting details and shades. If you've always wanted to get a couple of white skins, the CS.MONEY Blog has come to the rescue! We've put together a selection of the best white skins of 2023, peppered with images, video animations, and price charts. Open and look!`,
            src: '/img/belye-skiny-ksgo.jpeg',
            alt: 'skins',
            link: '/the-best-white-skins-in-cs'
        },
        {
            id: 4,
            title: "CS 2 config - how to install a new config in CS 2",
            text: `In CS 2, the option of configs is available - the player’s personal settings in the shooter. However, Valve slightly changed the conditions for its transfer from CS:GO. Below is a guide on how to install a new config in CS 2.`,
            src: '/img/cs2blue.webp',
            alt: 'config cs2',
            link: '/config-cs2'
        },
    ];

    return(
        <div>
            <Header type="mini" />
            <section className={style.contacts_section}>
                <Container>
                    <div className={style.contacts_section__title}>
                        <Title TAG="h2" text="Blog" />
                    </div>
                    <span className={style.contacts_section__sub}>
                        Here you can find out the latest news from the world of CS 2.
                    </span>
                    <div className={style.content_wrapper}>
                        {Items.map((item) => (
                            <div key={item.id} className={style.content_card}>
                                <Link className={style.content_link} to={item.link}>
                                    <img className={style.content_img} src={item.src} width={280} height={200} alt={item.alt} />
                                    <h2 className={style.content_title}>{item.title}</h2>
                                    <p className={style.content_text}>{item.text}</p>
                                </Link>
                            </div>
                        ))}
                    </div>
                </Container>
            </section>
            <Footer />
        </div>
    )
}

export default BlogPage;