import style from './style.module.css';

import Container from '@components/Container'
import Title from '@components/Title';
import AdvItem from '@components/AdvItem';
import InfoBlock from '@components/InfoBlock';



const AdvSection = ({type = "info"}) => {

    const advItems = [
        {
            title: 'No Cheats',
            desc: 'No 3rd party programs',
            src: './img/no-cheats.png'
        },
        {
            title: 'Fast Service',
            desc: '7-9 Wins / Day',
            src: './img/fast-service.png'
        },
        {
            title: 'Quality',
            desc: '4.5k+ Elo & FPL-C Boosters',
            src: './img/quality.png'
        },
    ]

    const infos = [
        {
            title: 'About our CS:GO boosting service: ',
            text: `Divine boost is a company based in Russia, created by 
            ex-pro players and CS:GO players with many outstanding achievements 
            in Counter-strike, such as: FPL-C, Rank G & Rank S.. We offer the most 
            professional, reliable and trustful boosting service that has satisfied 
            thousands of customers around the globe. All members of our company are 
            aimed to make your boosting as much comfortable, enjoyable and fun as possible. `
        },
        {
            title: 'Why  us?',
            text: `
            We offer one of the best and cheapest qualit
            y boosting services that exist in the market that could 
            satisfy any customer, alongside with very friendly environment. 
            Cheap prices, respectful customer service, fast and innovative 
            approach to boosting is what will satisfy you the most, come and 
            check it yourself!
            `
        },
        {
            title: 'Our services:',
            text: `Currently, our company offers two quality matchmaking and faceit boosting services:

            Matchmaking boost: - boosting is available from silver one all the way up to the maximum rank of the global elite!
            
            Faceit boost: One of the most popularized and demanded boosting services we offer. We are able to boost your ELO from 1 all the way to 3200 ELO!  `
        },
        {
            title: 'Boost options:',
            text: `Solo = account sharing. The cheapest and fastest option available. Booster itself will play from your account

            Lobby boost (50%) = playing by yourself. The following option is more expensive and requires more time, however you get the opportunity to play by yourself and enjoy the quality gameplay with our booster assigned to your order.
            
            Express boost (20%) - With express boost option you will be able to achieve your desired level much faster, as your account will have greater priority over other incoming orders!`
        },

    ]

    const data = [
        {
          "title": "Item 1",
          "description": "Lorem ipsum."
        },
        {
          "title": "Item 2",
          "description": "Lorem ipsum."
        }
      ]

    return(
        <section className={style.adv_section}>
            <Container>

                {type === "info" ?
                    <>
                        <Title TAG="h2" text="Over of 5 years Service Experience" />
                        <div className={style.adv_section__desc_wrap}>
                            <p className={style.adv_section__desc}>
                                The company’s main goal is to help our customers progress 
                                further in the area of competitive counter-strike. To overcome 
                                any possible frustrations and progress further, showing the way a 
                                game of counter-strike can be played from a different, professional perspective.
                            </p>
                            <p className={style.adv_section__desc}>
                                If you have any questions, please contact us. We are here to help you!
                            </p>         
                        </div>
                    </>
                     : 
                    null
                 }

                
                <div className={style.adv_section__advantages}>
                    {
                        advItems.map((advItem, idx) => {
                            return <AdvItem key={idx} title={advItem.title} desc={advItem.desc} src={advItem.src} />
                        })
                    }
                </div>

                {type === "info" ? <InfoBlock infos={infos} /> : null}
                
                
            </Container>
        </section>
    )
}

export default AdvSection;