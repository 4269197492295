import style from './style.module.css';

import {
    Link
} from "react-router-dom";  

const Nav = () => {  

    const routes = [
        {
            name: 'CS2 Boost',
            route: '/matchmakingboost',
        },
        {
            name: 'Faceit Boost',
            route: '/faceitboost',
        },
        {
            name: 'Accounts',
            route: '/cs2accounts',
        },
        {
            name: 'CONTACT & ABOUT',
            route: '/contacts',
        },
        {
            name: 'Blog',
            route: '/blog',
        },
    ]

    return(
        <nav>
            {routes.map((link, idx) => {
                return(
                    <Link to={link.route} key={idx} className={style.nav__link}>
                        {link.name}
                    </Link>
                )
            })}
        </nav>
    )
}

export default Nav;