import style from './style.module.css';

import CalcDesc from '@components/CalcDesc';
import Container from '@components/Container';
import Header from '@components/Header';
import Row from '@components/Row';
import Col from '@components/Col';
import AccountCard from '@components/AccountCard';
import AccountContainer from '@components/AccountContainer';
import Footer from '@components/Footer';

import { useEffect, useState } from 'react';

const AccountsPage = () => {
    const [accounts, setAccounts] = useState([]);
    
    useEffect(() => {
     fetch('https://divineboost.org/api/accounts.php')
     .then((results) => {
        return results.json();
     })
     .then((accs) => {
        setAccounts(accs)
     })
    }, []);

    const levelsType = [
        "Faceit level 4-10",
        "Mid Tier/ High Elo",
        "High Tier Accounts",
        "Faceit Ready",
        "Steam Ready"
    ];

    return(
        <div className={style.accounts_page}>
            <Header type="mini" />
            <div className={style.accounts_page__desc}>
                <CalcDesc
                    title={`Buy / Sell CSGO Faceit
                        `}
                    desc={`
                        We're trusted sellers with more than 5 years of service experience and now proudly representing the
                        largest spectrum of faceit and esea accounts on the market.
                        Our prices are competitive, our services are of high quality and we are known for our swiftness and
                        efficiency.
                        Can't seem to find the right Account/Price? Talk to us about it and we will assist you as soon as possible.
                    `}
                />
            </div>    
            <Container>
                {levelsType.map((title)=>(
                    <AccountContainer key={title} title={title}>
                        <Row>
                            {accounts.filter((account)=>account.elo >= 1101).map((account) => (
                                <Col key={account.id}>
                                    <AccountCard data={account} />
                                </Col>
                            ))}
                        </Row>
                    </AccountContainer>
                ))}
                <div className={style.accounts_page__offers}>
                    <h5>
                        Account offers:
                    </h5>
                    <p>
                        1. Matchmaking Ranked Smurf Accounts ( both Prime & Nonprime - Any Rank). <br />
                        2. Faceit level 3 4 5 6 7 8 9 - lvl 10 2000 Elo + Accounts including Steam & CSGO profile.
                    </p>
                    <p>
                        After purchase you will receive account information (login faceit/steam, password faceit/steam and first <br />
                        email details) on your email.All accounts have disabled steam guard, you can instantly connect to account <br />
                        and add your phone, change email and anything you want.
                    </p>
                    <p>
                        Buy or Sell your CSGO Rank / Faceit <br />
                        https://divineboost.org/
                    </p>
                </div>
            </Container>
            <Footer type="mini" />
        </div>
    )
}

export default AccountsPage;