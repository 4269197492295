import style from './style.module.css';

import Container from '@components/Container';
import Title from '@components/Title';
import WorkItem from '../WorkItem';

const WorksSection = () => {

    const workItems = [
        {
            title: "Fill The Form & 'Add to Cart'",
            text: `Pricing will be automatically calculated based on
            your Current And Desired Rank. It’s
            recommended but not necessary to temporarily
            change your password while boosting.`,
            src: '/img/work1.png',
            alt: 'cart'
        },
        {
            title: "Fill The Form & 'Add to Cart'",
            text: `You will be redirected to a safe & secure
            checkout page where you’ll be able to select your
            payment option.
            PayPal is one of the biggest online payment
            providers worldwide and is 100% safe.`,
            src: '/img/work2.png',
            alt: 'check'
        },
        {
            title: "Fill The Form & 'Add to Cart'",
            text: `Our booster will be assigned to your order within
            ~2 hours. Please be patient, boosters usually are
            in the middle of other orders, and they have to
            finish their current job in order to start your boost.`,
            src: '/img/work3.png',
            alt: 'man'
        },
    ]

    return(
        <section className={style.works_section}>
            <Container>
                <div className={style.works_section__title}> 
                    <Title TAG="h3" text="How it works?" />
                </div>
                <span className={style.works_section__sub}>
                    Just 3 Simple & Quick steps to boost your rank.
                </span>
                <div className={style.works_section__items}>
                    {workItems.map((item, idx) => {
                        return <WorkItem key={idx} title={item.title} alt={item.alt} src={item.src} text={item.text} />
                    })}
                </div>
            </Container>
        </section>
    )
}

export default WorksSection;