import style from './style.module.css';

import {useState} from 'react';

import Header from "@components/Header";
import Calculator from "@components/Calculator";
import Container from "@components/Container";
import Button from "@components/Button";
import AdvSection from "@components/AdvSection";
import DescSection from "@components/DescSection";
import CalcDesc from "@components/CalcDesc";
import Footer from "@components/Footer"
import Modal from "@components/Modal"
const FaceitBoostingPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [info, setInfo] = useState({
        from: 0,
        to: 0,
        price: 0,
        lobby: false,
        express: false,
    })

    const ranks = [
        {
            id: 1,
            name: 'LVL 1',
            src: 'img/ranks/CALC_Faceit 1.svg',
            elo: 0,
            price: 0,
        },
        {
            id: 1,
            name: 'LVL 1',
            src: 'img/ranks/CALC_Faceit 1.svg',
            elo: 651,
            price: 12,
        },
        {
            id: 1,
            name: 'LVL 2',
            src: 'img/ranks/CALC_Faceit 2.svg',
            elo: 801,
            price: 15,
        },
        {
            id: 1,
            name: 'LVL 3',
            src: 'img/ranks/CALC_Faceit 3.svg',
            elo: 951 ,
            price: 17,
        },
        {
            id: 1,
            name: 'LVL 4',
            src: 'img/ranks/CALC_Faceit 4.svg',
            elo: 1101,
            price: 19,
        },
        {
            id: 1,
            name: 'LVL 5',
            src: 'img/ranks/CALC_Faceit 5.svg',
            elo: 1251,
            price: 21,
        },
        {
            id: 1,
            name: 'LVL 6',
            src: 'img/ranks/CALC_Faceit 6.svg',
            elo: 1401,
            price: 24,
        },
        {
            id: 1,
            name: 'LVL 7',
            src: 'img/ranks/CALC_Faceit 7.svg',
            elo: 1551,
            price: 30,
        },
        {
            id: 1,
            name: 'LVL 8',
            src: 'img/ranks/CALC_Faceit 8.svg',
            elo: 1710,
            price: 36,
        },
        {
            id: 1,
            name: 'LVL 9',
            src: 'img/ranks/CALC_Faceit 9.svg',
            elo: 1851,
            price: 42,
        },
        {
            id: 1,
            name: 'LVL 10',
            src: 'img/ranks/CALC_Faceit 10.svg',
            elo: 2001,
            price: 72,
        },
        {
            id: 1,
            name: 'LVL 10',
            src: 'img/ranks/CALC_Faceit 10.svg',
            elo: 2201,
            price: 90,
        },
        {
            id: 1,
            name: 'LVL 10',
            src: 'img/ranks/CALC_Faceit 10.svg',
            elo: 2401,
            price: 114,
        },
        {
            id: 1,
            name: 'LVL 10',
            src: 'img/ranks/CALC_Faceit 10.svg',
            elo: 2601,
            price: 168,
        },
        {
            id: 1,
            name: 'LVL 10',
            src: 'img/ranks/CALC_Faceit 10.svg',
            elo: 2801,
            price: 168,
        },
        {
            id: 1,
            name: 'LVL 10',
            src: 'img/ranks/CALC_Faceit 10.svg',
            elo: 3001,
            price: 216,
        },
    ]

    const onChangeInfo = (info) => {
        setInfo({
            from: info.from,
            to: info.to,
            price: info.price,
            lobby: info.lobby,
            express: info.express,
        })
    }

    const openModal = () => {
        console.log(info)
        setIsModalOpen(true);
    }

    const closeModal = (e) => {
        e.stopPropagation();
        setIsModalOpen(false);
    }

    return(
        <div>
            <Modal 
                isOpened={isModalOpen}
                closeModal={closeModal}
                from={info.from}
                to={info.to}
                price={info.price}
                express={info.express}
                lobby={info.lobby}
            />
            <Header type="mini" />
            <CalcDesc
                title="Faceit Boosting"
                desc={`
                    Faceit boosting is option where you can buy desired level, pay per win or just elo boost.
                    Our High level boosting orders are done with minimum 2 boosters in the lobby for higher win rate.
                    Most reliable prices and secure faceit leveling service done by semi-pro FPL Challanger players.
                `}
            />
            <Container additStyles={style.mmboostpage}>
                <div className={style.mmboostpage__calculator}>
                    <h3>Cost calculation</h3>
                    <Calculator data={ranks} min={1} max={3200} type="faceit" initialValues={[651, 802]} onChangeInfo={onChangeInfo} />
                    <div className={style.mmboostpage__button} onClick={openModal}>
                        <Button text="BUY" icon="cart" link="/" TAG='div' />
                    </div>
                </div>
            </Container>
            <AdvSection type="noinfo" />
            <DescSection />
            <Footer />
        </div>
    )
}

export default FaceitBoostingPage;