import style from './style.module.css';

import Container from '@components/Container';
import Logo from '@components/Logo';
import Nav from '@components/Nav';

const Header = ({type = "default"}) => {
    return(
        <header className={type === "default" ? style.header : `${style.header} ${style.header__mini}`}>
            <Container additStyles={style.header__container}>
                <Logo src="/img/logo.png" />
                <Nav />
            </Container>

            {type === "default" ?
                <Container>
                    <h1 className={style.header__title}>
                        <span>
                            Professional CS2 <br />
                            Boosting Service <br />
                        </span>
                        Safe & Reliable
                    </h1>
                </Container> : null
            }
            
        </header>
    )
}

export default Header;