import style from './style.module.css';

import Header from '@components/Header';
import Container from '@components/Container';
import Button from '@components/Button'
import WorksSection from '@components/WorksSection';
import ResultsSection from '@components/ResultsSection';
import Footer from '@components/Footer';

import { useParams, withRouter} from 'react-router';
import {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

const SellPage = () => {

    let { addId } = useParams();
    const IMAGE_SRC = 'https://divineboost.org/uploads';
    const [account, setAccount] =  useState(null)
    const [type, setType] = useState(null)
    const [currentImage, setCurrentImage] = useState('');

    useEffect(() => {
        fetch(`https://divineboost.org/api/accounts.php?id=${addId}`)
        .then((res) => {
            return res.json();
        })
        .then((account) => {
            setAccount(account[0])
            setType(account[0].type)
            
        })
    }, [])

    useEffect(() => {
        setCurrentImage(sortImages()[0] == undefined ? '' : sortImages()[0]);
    }, [type])

    const sortImages = () => {
        const order = [
            'steamimage',
            'faceitimage',
            'faceitstats',
            'gameimage',
            'other'
        ]

        const result = [];

        if(account) {
            for(let i = 0; i < order.length; i++) {
                for(let k = 0; k < account.images.length; k++) {
                    if(order[i] == account.images[k].type) {
                        result.push(account.images[k]);
                    }
                }
            }
        }

        return result;
    }

    const onImageChange = (id) => {
        setCurrentImage(sortImages().find((image) => {
            return image.id === id;
        }))
    }
    
    return(
        <div>
            <Header type="mini" />
            <div className={style.sell_page}>
                <Container>
                    <div className={style.sell_page__account_info}>
                        <div className={style.sell_page__account_images}>
                            <div className={style.sell_page__main_image_wrap}>
                                <img src={currentImage ? `${IMAGE_SRC}/${currentImage.path}` : null} alt="rank" />
                            </div>
                            <div className={style.sell_page__images_line}>
                                {
                                    sortImages().map((image, idx) => {
                                        return (
                                            <div className={`${ image.id === currentImage.id ? style.im_wrap_active + ' ' + style.im_wrap : style.im_wrap }`} onClick={() => {onImageChange(image.id)}}>
                                                <img src={`${IMAGE_SRC}/${image.path}`} alt="img" />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className={style.sell_page__desc}>
                                

                                Rank Boosting is an option where you can select current and <br />
                                desired rank, the price will be automatically generated and <br />
                                discount is already included in total prices. CSGO Rank Boost <br />
                                is available in 2 options: Solo & Duo. <br /> <br />

                                Solo - Account Sharing option, where we will play from your <br />
                                account untill we reach desired rank. <br />

                                Duo - Self-play option, we will invite you in the lobby and we <br />
                                will play together untill we reach the ending point. <br /> <br />

                                Looking for 10 Placement Matches? Click <a href="#">HERE</a>.

                            </div>
                        </div>
                        <div className={style.sell_page__account__bill}>
                            <span className={style.sell_page__account_name}>
                                {account ? account.name : null}
                            </span>
                            <span className={style.sell_page__account_price}>
                                € {account ? account.price : null} Price 
                            </span>
                            <div className={style.sell_btn}>
                                <Link to='/contacts' className={style.link}>
                                    <Button text="Contact Us to Purchase" />
                                </Link>
                            </div>
                            <div className={style.sell_page__info}>
                                <span>Information:</span>
                                {
                                    type === "faceit" ? 
                                    <ul className={style.account_card__benefits}>
                                        <li>
                                            {account ? account.name : null}
                                        </li>
                                        <li>
                                            {account ? account.elo : null} Elo
                                        </li>
                                        <li>
                                            {account ? account.points : null} Points
                                        </li>
                                        <li>
                                            {account ? account.kd : null} K/D
                                        </li>
                                        <li>
                                            Instart delivery
                                        </li>
                                    </ul> :
                                    <ul className={style.account_card__benefits}>
                                        <li>
                                            { account ? account.name : null}
                                        </li>
                                        <li>
                                            {account ? account.hours : null} Hours
                                        </li>
                                        <li>
                                            Rank: {account ? account.rank : null}
                                        </li>
                                        <li>
                                            Instart delivery
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </Container>
                <WorksSection />
                <ResultsSection />
            </div>
            <Footer />
        </div>
    )
}

export default withRouter(SellPage) ;