import { useEffect, useState } from 'react';

import style from './style.module.css';
import 'rc-slider/assets/index.css';
import './style.css';
import Select from 'react-select';

import Checkbox from '@components/Checkbox';

const CalculatorMM = ({onChangeInfo}) => {
    let [price, setPrice] = useState(0);
    let [isExpressBoost, setIsExpressBoost] = useState(false);
    let [currentRankPrice, setCurrentRankPrice] = useState(1.7);
    let [winsCount, setWinsCount] = useState(1);
    let [boostModePercent, setBoostModePercent] = useState(1);

    const data = {
        selectors: [
            {
                name: 'current-rank',
                label: 'Current Rank',
                options : [
                    { value: 1.7, label: '0-4999' },
                    { value: 2.3, label: '5000-9999' },
                    { value: 3, label: '10000-14999' },
                    { value: 3.5, label: '15000-19999' },
                    { value: 6, label: '20000-24999' },
                    { value: 10, label: '25000-29999' },
                    { value: 15, label: '30000-35000' },
                  ],
            },
            {
                name: 'wins-count',
                label: 'Number of wins',
                options : [
                    { value: 1, label: '+1 win' },
                    { value: 2, label: '+2 wins' },
                    { value: 3, label: '+3 wins' },
                    { value: 4, label: '+4 wins' },
                    { value: 5, label: '+5 wins' },
                    { value: 6, label: '+6 wins' },
                    { value: 7, label: '+7 wins' },
                    { value: 8, label: '+8 wins' },
                    { value: 9, label: '+9 wins' },
                    { value: 10, label: '+10 wins' },
                  ],
            },
            {
                name: 'boost-type',
                label: 'Type boost',
                options : [
                    { value: 1, label: 'Solo' },
                    { value: 1.5, label: 'Party' },
                  ],
            }
        ],
        checkboxes: [
            {
                id: 'express-boost',
                label: 'Express boost (+20%)',
                value: isExpressBoost,
            },
        ]
    }
    
    const onChangeSelect = (option, actionMeta) => {
        if(actionMeta.name === 'current-rank') {
            setCurrentRankPrice(option.value);
        }
        if(actionMeta.name === 'wins-count') {
            setWinsCount(option.value);
        }
        if(actionMeta.name === 'boost-type') {
            setBoostModePercent(option.value);
        }
    }

    const onChangeCheckbox = (id) => {
        if (id === 'express-boost') setIsExpressBoost(!isExpressBoost);
    }

    const calculate = ()=> {
        let newPrice = winsCount * currentRankPrice * boostModePercent;
        if (isExpressBoost) newPrice = newPrice * 1.2;
        setPrice(Math.ceil(newPrice));
        return newPrice;
    }

    useEffect(()=>{
        onChangeInfo({
            from: data.selectors[0].options.filter((option)=> option.value === currentRankPrice)[0].label,
            to: winsCount,
            price: calculate(),
            lobby: boostModePercent !== 1,
            express: isExpressBoost,
        });

    }, [isExpressBoost, boostModePercent, winsCount, currentRankPrice]);

    useEffect(()=>{
        onChangeInfo({
            from: data.selectors[0].options.filter((option)=> option.value === currentRankPrice)[0].label,
            to: winsCount,
            price: calculate(),
            lobby: boostModePercent !== 1,
            express: isExpressBoost,
        });
    }, []);

    return(
        <>
            <div className={style.calculator__block}>
                <div className={style.calculator__img}>
                    <img src="/img/csboost.png" />
                </div>
                <div>
                    {
                        data.selectors.map((select)=>(
                            <div key={select.name}>
                                <label style={{display: 'block',color: '#fff', marginBottom: '10px', marginTop: '10px'}} id={`${select.name}-label`} htmlFor={select.name}>
                                    {select.label}
                                </label>
                                <Select
                                    aria-labelledby={`${select.name}-label`}
                                    inputId={select.name}
                                    name={select.name}
                                    defaultValue={select.options[0]}
                                    options={select.options}
                                    onChange={onChangeSelect}
                                />
                            </div>
                        ))
                    }
                    {data.checkboxes.map((checkbox)=>(
                        <div className={style.calculator__checkbox} key={checkbox.id}>
                            <Checkbox value={checkbox.value} onCheckBoxClick={onChangeCheckbox} id={checkbox.id} />
                            <span className={style.calculator__label}>
                                {checkbox.label}
                            </span>
                        </div>
                    ))}
                    <div className={style.calculator__wrapper}>
                        <span >Calibration €25</span>
                        <span>Calibration at party €35</span>
                        <span>Important! For calibration please contact <a href="https://discord.gg/U7V3RUCtnu">Discord</a></span>
                    </div>
                </div>

            </div>
            <span className={style.calculator__price}>
                Total price: <span>€{price}</span>
            </span>
        </>
    )
}

export default CalculatorMM;