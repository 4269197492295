import style from './style.module.css';

import Container from '@components/Container';
import Logo from '@components/Logo';
import SocialMedia from '@components/SocialMedia';

const Footer = () => {   

    const navLinks = [
        {
            name: 'Rank Boost',
            link: '/matchmakingboost',
        },
        {
            name: 'FACEIT Boost',
            link: '/faceitboost',
        },
        {
            name: 'Accounts',
            link: '/cs2accounts',
        },
        {
            name: 'Contact',
            link: '/contacts',
        },
        
    ]

    return(
        <footer>
            <Container additStyles={style.footer__container}>
                <div className={style.footer__line}>
                    <div className={style.footer__col}>
                        <Logo src="/img/footer-logo.png" />
                    </div>
                    <div className={style.footer__col}>
                        <nav className={style.footer__nav}>
                            {navLinks.map((link, idx)=> {
                                return <a className={style.footer__nav_link} href={link.link} key={idx}>
                                    {link.name}
                                </a>
                            })}
                        </nav>
                        <div className={style.footer__social_media}>
                            <SocialMedia />
                        </div>
                        <div className={style.footer__payment}>
                            <a href='https://www.trustpilot.com/review/divineboost.org' className={style.footer__trust}>
                                <img src='img/trust.png' alt="trust" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className={style.footer__line}>
                    <div className={style.footer__col}>
                        <div className={style.footer__info}>
                            © 2015-2023 divineboost.org <br />
                            Counter-Strike: Global Offensive is a registered trademark of Valve Corporation Company. <br />
                            We are in no way affiliated with, associated with or endorsed by Valve Corporation. <br />
                            All rights reserved. <br /> <br />

                            <a href="mailto:artemkasatr@gmail.com">artemkasatr@gmail.com</a>
                        </div>
                    </div>
                </div>
            </Container>
        </footer>
    )
    
}

export default Footer;