import style from "./style.module.css";
import Header from '@components/Header';
import Container from '@components/Container';
import Footer from '@components/Footer';

const BlogConfigCs2 = () => {
    return (
    <div>
        <Header type="mini"/>
        <section className={style.contacts_section}>
            <Container>
                <div className={style.content_article}>
                    <h2>CS 2 config - how to install a new config in CS 2</h2>

                    <p>In CS 2, the option of configs is available - the player’s personal settings in the shooter. However, Valve slightly changed the conditions for its transfer from CS:GO. Below is a guide on how to install a new config in CS 2.</p>
                    <h3>How to transfer a config from CSGO to Counter-Strike 2</h3>
                    <p>Go to the CS:GO config folder, where the “config.cfg” file is stored. It is in the following thread:</p>
                    <p>Steam/userdata/id account/730/local/cfg</p>

                    <h3>How to install a new config in CS 2</h3>
                    <p>Valve has adjusted the path for installing the config in CS 2. Take the copied config.cfg file from the CS:GO folder and move it to the following branch:</p>
                    <p>Steam/steamapps/common/Counter Strike:Global Offensive/game/csgo/cfg</p>

                    <h3>How to enable config in CS 2</h3>
                    <p>
                        Go to the shooter and call the console using the “~” button. Then write the command “exec config.cfg” without quotes. You can change the file name to any word instead of "config". Please note that not all config parameters can be transferred to CS 2.</p>
                </div>
            </Container>
        </section>
        <Footer/>
    </div>
    )
}

export default BlogConfigCs2;