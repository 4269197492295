import style from "./style.module.css";
import Header from '@components/Header';
import Container from '@components/Container';
import Footer from '@components/Footer';

const BlogSkins = () => {
    return (
    <div>
        <Header type="mini"/>
        <section className={style.contacts_section}>
            <Container>
                <div className={style.content_article}>
                    <h2>Best White Skins in CS</h2>

                    <h3>USP-S Whiteout, the best white pistol skin</h3>
                    <p>In a white inventory, you can’t do without a skin from the Whiteout family. The white skin quickly becomes covered with scratches as the float grows, so you should look for copies in Minimal Wear or Field-Tested condition.</p>
                    <img src="/img/Usp.webp" alt="usp"/>

                    <h3>AK-47 Asiimov, the best white skin for AK</h3>
                    <p>Among the AK-47 with white color, it is difficult to choose the best candidate AK-47 Asiimov.</p>
                    <img src="/img/Ak.webp" alt="ak-47"/>

                    <h3> M4A1-S Printstream, best white skin for M4A1-S</h3>
                    <p>Among the white-colored M4A1-S, it’s hard to choose the best candidate, the M4A1-S Printstream.</p>
                    <img src="/img/m4a1.webp" alt="m4a1-s"/>

                    <h3>AWP Asiimov, the best white skin on AWP</h3>
                    <p>Another Asiimov skin, but for Avik. Just like the AK-47, the majority of this skin is pure white, with parts of the rifle being painted orange and black.</p>
                    <img src="/img/awp.webp" alt="ak-47"/>

                </div>
            </Container>
        </section>
        <Footer/>
    </div>
    )
}

export default BlogSkins;