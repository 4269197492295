import style from './style.module.css';

import Container from '@components/Container';
import Title from '@components/Title';
import {AccordionItem, AccordionWrapper} from 'custom-react-accordion';

const AccordionSection = () => {

    const data = [
        {
            "title": "No third party programs",
            "description": `Our company’s mission is to offer legitimate, 
            safe and secure boosting experiences to our customers. Thus, we are 
            strongly against any possible usage of 3rd party programs, to keep you 
            safe from any unsatisfactory experience, alongside with our trustful 
            reputation dedication to our customers. `
          },
          {
            "title": "Is boosting safe? can i get banned?",
            "description": `Boosting from our company is 100% safe and secure, the job is done first of all to get our customers satisfied, 
            not vice versa. We are aimed to make your boosting experience as enjoyable and safe as possible. `
          },
          {
            "title": "How do I purchase the boost?",
            "description": `In order to purchase the boost, please select the boosting option you wish to be boosted. 
            After doing so, head on to the contacts section on our website and contact us via the most convenient contact method available. `
          },
          {
            "title": "How long will my boost take?",
            "description": `This depends on the difficulty of your order, however we can ensure that our boosters win from 6-10 games a day! `
          },
          {
            "title": "I don't like the price, can i get a discount?",
            "description": `
            If you are concerned about the price assigned within your order, please contact us via the most convenient contact method available on our website. `
          },
          {
            "title": "I've made order. When does it start?",
            "description": `After you have purchased your order, we will begin your order based on the workload available. Usually we start boosting after the order is placed.`
          },
    ]

    return(
        <div className={style.accordion_section}>
            <Container>
                <div className={style.accordion_section__title}>
                    <Title text="CSGO Boosting –
                    Frequently Asked Questions" TAG="h3" />
                </div>
                
                <AccordionWrapper>
                    {data.map((item, index) => (
                        <AccordionItem key={index} index={index} title={item.title} description={item.description} />
                    ))}
                </AccordionWrapper>
            </Container>
        </div>
    )
}

export default AccordionSection;