import style from './style.module.css';

import Title from '@components/Title';
import Container from '@components/Container';

const DescSection = () => {
    return(
        <section className={style.desc_section}>
            <Container>
                <div className={style.desc_section__title}>
                    <Title TAG="h3" text="CSGO Rank Boosting Service" />
                </div>
                <div className={style.desc_section__text}>
                    CS:GO Ranked Boosting Service - is an option where you can buy desired rank, win or placement
                    unranked matches.
                    In placements rank boost, the maximum rank you will achieve is Legendary Eagle Master .
                    Rank Boost is available both Solo and Duo boosting option .
                    We offer boost in EU and NA , for other Regions we can only provide Solo mode, or you can join lobby
                    but you will have over 100 ping.
                    Our service also offers csgo boosting in wingman per rankups.
                    Our prices are cheap comparing to our competitors and in the same time, skills and quality in general,
                    when it comes down CS:GO boostings is on the highest possible level .
                </div>
            </Container>
        </section>
    )
}

export default DescSection;